import C from "../constants"
import m from "mithril"
import api from "../api"
import utils from "../utils"
import Solana from "../solana"

import Post from "../components/post"
import Layout from "./layout"

export default function UserPage(vnode) {
  let user = null
  let profile = null
  // let favorite = e => {}
  let favorite = null

  return {
    oninit: async vnode => {
      let sol = new Solana()
      let wallet = vnode.attrs.username
      if (wallet.length < 40) {
        wallet = await sol.resolveStiiksSubdomain(wallet.toLowerCase())
      }
      profile = await sol.onchainProfile(wallet)
      if (profile) {
        user = await api.storageProfile(profile.address)
        m.redraw()

        for (let post of user.timeline) {
          api.populatePost(post)
        }
      }
    },

    view: vnode => {
      if (!user || !profile)
        return

      return <Layout>
        <div id="profile" class={user.banner && `banner-${user.banner.style}`}>
          {user.banner && user.banner.style == "top" && <div class="banner"><img src={user.banner.url} /></div>}
          <div class="h-card">
            <div class="avatar">
              <sl-avatar image={user.avatar.url}
                label={`@${user.shortname}`} shape="rounded"></sl-avatar>
            </div>
            <div class="bio">
              <h2>{user.nametag}
                {favorite && <sl-icon-button onclick={favorite}
                  name="star" label="Favorite"></sl-icon-button>}
              </h2>
              {m.trust(user.bio)}
            </div>
            <div class="community">
              <div class="line">
                <p>{m(m.route.Link, {href: `/@${user.username}`}, `@${user.shortname}`)}</p>
                {user.url && <p><sl-icon name="globe"></sl-icon>
                  <a href={user.url} target="_blank" rel="noopener noreferrer">{user.url.host}</a></p>}
              </div>
            </div>
          </div>
          <div class="sidebar">
            {user.banner.url && user.banner.style == "side" && <img src={user.banner.url} />}
            <a href="/"><img class="logo" src={new URL("../images/stiiks-social.png", import.meta.url)} style="padding-top: 32px" /></a>
            {vnode.attrs.edit && <sl-button onclick={() => m.route.set("/setup")}>Edit Profile</sl-button>}
            <sl-button onclick={e => m.route.set("/post")}
              variant="primary">Post</sl-button>
          </div>
          <div class="main">
            <sl-tab-group>
              <sl-tab slot="nav" panel="recent">Recent</sl-tab>
              <sl-tab slot="nav" panel="replies" disabled>Replies</sl-tab>
              <sl-tab slot="nav" panel="likes" disabled>Likes</sl-tab>

              <sl-tab-panel name="recent">
                <ol class="timeline">
                  {user.timeline.map(post =>
                    <Post author={user} post={post} style="brief" />
                  )}
                </ol>
              </sl-tab-panel>
              <sl-tab-panel name="replies"></sl-tab-panel>
              <sl-tab-panel name="likes"></sl-tab-panel>
            </sl-tab-group>
          </div>
        </div>
      </Layout>
    }
  }
}
