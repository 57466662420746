import m from "mithril"
import Wallet from "../components/wallet"

export default function WelcomePage() {
  return {
    view: vnode => {
      return <div>
        <Wallet />
        <div id="page" class="welcome">
          <h1>Welcome to Stiiks.Social</h1>
          <p style="text-align: center"><img src={new URL("../images/stiiks-golf.png", import.meta.url)} /></p>
          <div class="narrow">
            <p>It's basically like Twitter except:</p>
            <ul>
              <li>Every Stiik follows every Stiik.</li>
              <li>Your account is stored in your Solana wallet rather than being linked to e-mail or phone number.</li>
              <li>Your stuff is stored on blockchain and decentralized storage. (Site can't go away.)</li>
              <li>Costs 0.1 SOL to create an account. (Used to pay for storage.)</li>
            </ul>
            <p>So&mdash;you got a wallet with Stiiks or Sunnies connected?</p>
            <div class="buttons">
              <sl-button variant="primary" onclick={() => m.route.set("/setup")}>Yap, set me up!</sl-button>
            </div>
          </div>
        </div>
      </div>
    }
  }
}
