import {BinaryReader, BinaryWriter, deserializeUnchecked, serialize} from "borsh"
import {Connection, PublicKey, TransactionInstruction} from "@solana/web3.js"
import {getDomainKeySync, NameRegistryState} from "@bonfida/spl-name-service"
import baseX from "base-x"

var BASE58 = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz"
export const base58 = baseX(BASE58)
var BASE36 = "0123456789abcdefghijklmnopqrstuvwxyz"
const base36 = baseX(BASE36)
var BASE32 = "abcdefghijklmnopqrstuvwxyz234567"
const base32 = baseX(BASE32)

import Sunnies from '../../meta/sunnies.json' // assert {type: "json"};
import C from './constants.mjs'

let downbadsAccount
PublicKey.createWithSeed(
  C.OWNER_ACCOUNT,
  C.PROG_SEED,
  C.PROGRAM_ID,
).then(acct => downbadsAccount = acct)

// let rewardWallet
// PublicKey.findProgramAddress([
//   C.OWNER_ACCOUNT.toBuffer(), u8(C.PROG_SEED), C.PROGRAM_ID.toBuffer()],
//     C.PROGRAM_ID).then(([key, bump]) => {
//       rewardWallet = key
//     })

/*
 * From https://gist.github.com/dvcrn/c099c9b5a095ffe4ddb6481c22cde5f4
*/
const PubKeysInternedMap = new Map();
// Borsh extension for pubkey stuff
BinaryReader.prototype.readPubkey = function () {
  const reader = this;
  const array = reader.readFixedArray(32);
  return new PublicKey(array);
};
BinaryWriter.prototype.writePubkey = function (value) {
  const writer = this;
  writer.writeFixedArray(value.toBuffer());
};
BinaryReader.prototype.readPubkeyAsString = function () {
  const reader = this;
  const array = reader.readFixedArray(32);
  return base58.encode(array);
};
BinaryWriter.prototype.writePubkeyAsString = function (value) {
  const writer = this;
  writer.writeFixedArray(base58.decode(value));
};
const toPublicKey = (key) => {
  if (typeof key !== "string") {
    return key;
  }
  let result = PubKeysInternedMap.get(key);
  if (!result) {
    result = new PublicKey(key);
    PubKeysInternedMap.set(key, result);
  }
  return result;
};
const findProgramAddress = async (seeds, programId) => {
  const key =
    "pda-" +
    seeds.reduce((agg, item) => agg + item.toString("hex"), "") +
    programId.toString();
  const result = await PublicKey.findProgramAddress(seeds, programId);
  return [result[0].toBase58(), result[1]];
};
export var MetadataKey;
(function (MetadataKey) {
  MetadataKey[(MetadataKey["Uninitialized"] = 0)] = "Uninitialized";
  MetadataKey[(MetadataKey["MetadataV1"] = 4)] = "MetadataV1";
  MetadataKey[(MetadataKey["EditionV1"] = 1)] = "EditionV1";
  MetadataKey[(MetadataKey["MasterEditionV1"] = 2)] = "MasterEditionV1";
  MetadataKey[(MetadataKey["MasterEditionV2"] = 6)] = "MasterEditionV2";
  MetadataKey[(MetadataKey["EditionMarker"] = 7)] = "EditionMarker";
})(MetadataKey || (MetadataKey = {}));
class Creator {
  constructor(args) {
    this.address = args.address;
    this.verified = args.verified;
    this.share = args.share;
  }
}
class Data {
  constructor(args) {
    this.name = args.name;
    this.symbol = args.symbol;
    this.uri = args.uri;
    this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
    this.creators = args.creators;
  }
}
class Metadata {
  constructor(args) {
    this.key = MetadataKey.MetadataV1;
    this.updateAuthority = args.updateAuthority;
    this.mint = args.mint;
    this.data = args.data;
    this.primarySaleHappened = args.primarySaleHappened;
    this.isMutable = args.isMutable;
    this.editionNonce = args.editionNonce;
    this.collection = args.collection;
  }
}
class Collection {
  constructor(args) {
    this.verified = args.verified;
    this.key = args.key;
  }
}
const METADATA_SCHEMA = new Map([
  [
    Data,
    {
      kind: "struct",
      fields: [
        ["name", "string"],
        ["symbol", "string"],
        ["uri", "string"],
        ["sellerFeeBasisPoints", "u16"],
        ["creators", { kind: "option", type: [Creator] }],
      ],
    },
  ],
  [
    Creator,
    {
      kind: "struct",
      fields: [
        ["address", "pubkeyAsString"],
        ["verified", "u8"],
        ["share", "u8"],
      ],
    },
  ],
  [
    Metadata,
    {
      kind: "struct",
      fields: [
        ["key", "u8"],
        ["updateAuthority", "pubkeyAsString"],
        ["mint", "pubkeyAsString"],
        ["data", Data],
        ["primarySaleHappened", "u8"],
        ["isMutable", "u8"], // bool
        ["editionNonce", { kind: "option", type: "u16" }],
        ["collection", { kind: "option", type: Collection }],
      ],
    },
  ],
  [
    Collection,
    {
      kind: "struct",
      fields: [
        ["verified", "u8"],
        ["key", "pubkeyAsString"]
      ]
    }
  ]
]);

export async function getMetadataAccount(tokenMint) {
  return (
    await findProgramAddress(
      [
        Buffer.from(C.METADATA_PREFIX),
        toPublicKey(C.METADATA_PROGRAM_ID).toBuffer(),
        toPublicKey(tokenMint).toBuffer(),
      ],
      toPublicKey(C.METADATA_PROGRAM_ID)
    )
  )[0];
}

export async function getSolanaMetadataAddress(tokenMint) {
  const tokenKey = new PublicKey(tokenMint)
  const metaProgramPublicKey = C.METADATA_PROGRAM_ID
  const metaProgramPublicKeyBuffer = metaProgramPublicKey.toBuffer()
  const metaProgramPrefixBuffer = new TextEncoder().encode(C.METADATA_PREFIX)
  return (
    await PublicKey.findProgramAddress(
      [metaProgramPrefixBuffer, metaProgramPublicKeyBuffer, tokenKey.toBuffer()],
      metaProgramPublicKey
    )
  )[0]
}

export function u8(str, len = 0) {
  // return (new TextEncoder()).encode(str);
  if (len == 0)
    return Buffer.from(str)

  let buf = Buffer.alloc(len)
  buf.write(str, 0)
  return buf
}

export async function getDownbadsPDA(holder) {
  let seed = u8(C.USER_SEED, 8)
  let address
  while (seed[7] != 0) {
    address = await PublicKey.createWithSeed(holder, seed.toString(), C.PROGRAM_ID)
    if (!PublicKey.isOnCurve(address))
      return address
    seed[7]--
  }
  throw new Error(`Unable to find a viable program address`)
}

const METADATA_REPLACE = new RegExp("\u0000", "g")
export const decodeMetadata = (buffer) => {
  try {
    const metadata = deserializeUnchecked(METADATA_SCHEMA, Metadata, buffer)
    metadata.data.name = metadata.data.name.replace(METADATA_REPLACE, "")
    metadata.data.uri = metadata.data.uri.replace(METADATA_REPLACE, "")
    metadata.data.symbol = metadata.data.symbol.replace(METADATA_REPLACE, "")
    return metadata
  } catch (e) {
    console.log(e)
  }
}

async function getRemoteJson(mint) {
  mint.nft = await fetch(mint.data.uri).then(res => res.json())
	return mint
}

//
// Stiiks.Social transactions
//

//
// User profile call
//
class TxProfile {
  constructor(args) {
    this.code = 0x10
    this.version = args.version
    this.settings = 0
    this.refills = args.refills
    this.spend = args.spend
    this.profile = args.profile
  }
}
const TX_PROFILE_SCHEMA = new Map([
  [
    TxProfile,
    {
      kind: "struct",
      fields: [
        ["code", "u32"],
        ["version", "u32"],
        ["settings", "u32"],
        ["refills", "u32"],
        ["spend", "u32"],
        ["profile", ["u8"]],
      ],
    },
  ]
])

class TxProfileDetail {
  constructor(args) {
    this.storageType = args.storageType
    this.storageId = args.storageId
  }
}
const TX_PROFILE_DETAIL_SCHEMA = new Map([
  [
    TxProfileDetail,
    {
      kind: "struct",
      fields: [
        ["storageType", "u32"],
        ["storageId", ["u8"]],
      ],
    },
  ]
])

const IPNS_STORAGE = 0

export async function downbadsUserProfile(walletKey, avatarKey, storageId = null, refills = 0, spend = 0) {
  // const nftAccountKey = await getAssociatedTokenAccount(nftKey, payer.publicKey)
  // const nftMetaKey = await getTokenMetadataAccount(nftKey)
  let profile = u8("")
  if (storageId !== null) {
    profile = serialize(TX_PROFILE_DETAIL_SCHEMA, new TxProfileDetail({storageType: IPNS_STORAGE, storageId: u8(storageId)}))
  }
  const data = serialize(TX_PROFILE_SCHEMA, new TxProfile({version: 1, settings: 0, profile, refills, spend}))
  // console.log(data)
  const downbadsPda = await getDownbadsPDA(walletKey)
  return new TransactionInstruction({
    keys: [
      {pubkey: downbadsAccount, isSigner: false, isWritable: true},
      {pubkey: walletKey, isSigner: true, isWritable: true},
      {pubkey: downbadsPda, isSigner: false, isWritable: true},
      {pubkey: avatarKey, isSigner: false, isWritable: true},
      {pubkey: C.SYSTEM_ACCOUNT, isSigner: false, isWritable: false},
      {pubkey: C.TOKEN_PROGRAM_ID, isSigner: false, isWritable: false},
    ],
    programId: C.PROGRAM_ID,
    data
  })
}

export async function onchainMemo(nonce) {
  return new TransactionInstruction({
    keys: [],
    programId: C.MEMO_PROGRAM_ID,
    data: Buffer.from(nonce, 'utf8')
  })
}

//
// Close account call
//
export async function downbadsCloseProfile(walletKey) {
  const data = u8([0xF0, 0, 0, 0])
  // console.log(data)
  const downbadsPda = await getDownbadsPDA(walletKey)
  return new TransactionInstruction({
    keys: [
      {pubkey: downbadsAccount, isSigner: false, isWritable: true},
      {pubkey: walletKey, isSigner: true, isWritable: true},
      {pubkey: downbadsPda, isSigner: false, isWritable: true}
    ],
    programId: C.PROGRAM_ID,
    data
  })
}

//
// User refill call
//
// class TxRefill {
//   constructor(args) {
//     this.code = 0x20
//     this.amount = args.amount
//   }
// }
// const REFILL_SCHEMA = new Map([
//   [
//     TxRefill,
//     {
//       kind: "struct",
//       fields: [
//         ["code", "u32"],
//         ["amount", "u32"],
//       ],
//     },
//   ]
// ])
// 
// export async function downbadsUserRefill(walletKey, avatarKey, amount) {
//   const data = serialize(REFILL_SCHEMA, new TxRefill({amount}))
//   const downbadsPda = await getDownbadsPDA(walletKey)
//   return new TransactionInstruction({
//     keys: [
//       {pubkey: downbadsAccount, isSigner: false, isWritable: true},
//       {pubkey: walletKey, isSigner: true, isWritable: true},
//       {pubkey: downbadsPda, isSigner: false, isWritable: true},
//       {pubkey: avatarKey, isSigner: false, isWritable: true},
//       {pubkey: C.SYSTEM_ACCOUNT, isSigner: false, isWritable: false},
//     ],
//     programId: C.PROGRAM_ID,
//     data
//   })
// }

//
// Close account call
//
export async function downbadsVxT(walletKey) {
  const data = u8([0, 0x0F, 0, 0])
  const downbadsPda = await getDownbadsPDA(walletKey)
  return new TransactionInstruction({
    keys: [
      {pubkey: downbadsAccount, isSigner: false, isWritable: true},
      {pubkey: walletKey, isSigner: true, isWritable: true},
      {pubkey: downbadsAccount, isSigner: false, isWritable: true}
    ],
    programId: C.PROGRAM_ID,
    data
  })
}

/**
 * Get an account's onchain data
 */
class OnchainProfile {
  wallet;
  address;
  constructor(args) {
    this.avatar = args.avatar
    this.creds = args.creds
    this.spent = args.spent
    this.settings = args.settings 
    this.data = args.data
  }
}
const PROFILE_SCHEMA = new Map([
  [
    OnchainProfile,
    {
      kind: "struct",
      fields: [
        ["avatar", "pubkeyAsString"],
        ["creds", "u32"],
        ["spent", "u32"],
        ["earned", "u32"],
        ["unused1", "u32"],
        ["unused2", "u32"],
        ["unused3", "u32"],
        ["settings", "u32"],
        ["data", ["u8"]],
      ],
    },
  ]
])

/*
 * Solana API wrapper
 */
export default class Solana {
  constructor() {
    this.connection = new Connection(process.env.SOL_API, 'confirmed')
  }

  async getLatestBlockhash() {
    let res = await this.connection.getLatestBlockhash('finalized')
    return res.blockhash
  }

  async onchainProfile(wallet) {
    const addr = new PublicKey(wallet)
    const downbadsPda = await getDownbadsPDA(addr)
    const accountInfo = await this.connection.getAccountInfo(downbadsPda)
    if (accountInfo === null) {
      // throw 'Error: cannot find the user account';
      return null
    }
    let profile = deserializeUnchecked(PROFILE_SCHEMA, OnchainProfile, accountInfo.data)
    profile.wallet = wallet
    // TODO: derive the IPNS address from the wallet
    // let addrb = addr.toBuffer()
    // let cidb = new Uint8Array(addrb.length + 8)
    // cidb.set(new Uint8Array([1, 114, 0, 36, 8, 1, 18, 32]))
    // cidb.set(addrb, 8)
    // profile.address = "k" + base36.encode(addrb)
    let storage = deserializeUnchecked(TX_PROFILE_DETAIL_SCHEMA, TxProfileDetail, u8(profile.data))
    profile.address = (new TextDecoder("utf-8")).decode(u8(storage.storageId))
    return profile
  }

  async tokenAccounts(walletKey, query) {
    const { value: splAccounts } = await this.connection.
      getParsedTokenAccountsByOwner(walletKey, query)
    // console.log(splAccounts)
    return splAccounts.filter(t => (
      t.account?.data?.parsed?.info?.tokenAmount?.decimals === 0 &&
      t.account?.data?.parsed?.info?.tokenAmount?.uiAmount >= 1)).map(t =>
        t.account?.data?.parsed?.info?.mint)
  }

  async loadTokenAccounts(nftAccounts) {
    const metadataPromises = await Promise.allSettled(nftAccounts.map(getSolanaMetadataAddress))
    const metadataAccounts = metadataPromises.filter(({ status }) => status === 'fulfilled')
      .flatMap((p) => p.value) 
    let nftAccountInfos = []
    const chunkSize = 100
    for (let i = 0; i < metadataAccounts.length; i += chunkSize) {
      const chunk = metadataAccounts.slice(i, i + chunkSize);
      nftAccountInfos = nftAccountInfos.concat(await this.connection.getMultipleAccountsInfo(chunk))
    }
    // console.log(nftAccountInfos)
    const nftMints = nftAccountInfos
      .filter((account) => account !== null)
      .map((account) => decodeMetadata(account.data))
	    .sort((a, b) => (a?.data?.name || '').localeCompare(b?.data?.name || ''))
    return nftMints
  }

  async loadTokenMetadata(nftMints) {
    const nftPromises = await Promise.allSettled(nftMints.map(getRemoteJson))
    return nftPromises.filter(({ status }) => status === 'fulfilled')
      .map((p) => p.value)
  }

  async nftsOwnedBy(walletKey) {
    return await this.tokenAccounts(walletKey, { programId: C.TOKEN_PROGRAM_ID })
  }

  async isNftOwnedBy(nftKey, walletKey) {
    let tok = await this.tokenAccounts(walletKey, { mint: nftKey })
    return tok.length > 0
  }

  isAllAccessNft(token) {
    return Sunnies.includes(token.mint)
  }

  isQualifiedNft(token) {
    const STIIKS_COLLECTION = "RmEu5qDmSHyQiPtknFeG3KmHdiyj1BC7E9vubfAMZeA"
    if (token.collection && token.collection.key === STIIKS_COLLECTION && token.collection.verified == 1)
      return true
    if (token.mint === "9SZMRJJbQF99fFCxVqHs7MUAEH8GiKafBfnU3Xvr9ys3")
      return true
    return this.isAllAccessNft(token)
  }

  isQualifiedBanner(token) {
		const COLLECTIONS = [
			"6pHajVGAgsKz9V2idApU8UKcfqmU8eHToofwtLmA5Ex", // Sagas
			"6oBAj62pzbkqgTk5wc9JRfh8iLtqW3XPYDPih3qpSfQ", // Sunsets
      "7ddgXvtamaChjK7Qg26gXnKu8ScCS2P3za6evF1f92C", // Sunsets SE
      "6UAjRaYNDbGQ9Sq3wUhnD8AUs8yZt6DJqZt5xHxgb5v"  // Drops
		]
		return COLLECTIONS.includes(token.collection?.key)
  }

  async resolveStiiksSubdomain(name) {
    const {pubkey: subKey} = getDomainKeySync(`${name}.stiiks`)
    if (subKey) {
      const {registry} = await NameRegistryState.retrieve(
        this.connection, subKey)
      if (registry) {
        return registry.owner.toBase58()
      }
    }
  }
}
