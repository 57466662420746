import m from "mithril"
import utils from "../utils"
import api from "../api"
import {getFormControls} from '@shoelace-style/shoelace/dist/utilities/form.js'

import Layout from "./layout"

export default function SetupFinish(vnode) {
  let loading = false
  let saveProfile = async e => {
    if (!loading) {
      loading = true
      e.preventDefault()

      let state = {}
      const formControls = getFormControls(e.target)
      for (let i = 0; i <= 2; i++) {
        state[formControls[i].name] = formControls[i].value
      }
      state = utils.workingState(state)
      try {
        await api.signedTxLogin(state)
        m.route.set("/credits")
      } catch (e) {
        alert(e)
        loading = false
        m.redraw()
      }
    }
  }

  return {
    oncreate: vnode => {
      let state = utils.workingState()
      const formControls = getFormControls(document.getElementById("setup-form"))
      formControls.forEach(fc => {
        let s = state[fc.name]
        if (s) {
          fc.value = s
        }
      })
    },

    view: vnode => {
      return <Layout>
        <div id="page">
          <h1>Setup Your Profile</h1>

          <div class="narrow">
            <p>Technically you don't need any of this stuff. Just here in case you
              want to customize.</p>

            <form id="setup-form" onsubmit={saveProfile}>
              <sl-input name="nametag" label="Name" maxlength="50"></sl-input>
              <br />
              <sl-textarea name="bio" label="Bio" resize="auto" maxlength="160"></sl-textarea>
              <br />
              <sl-input name="url" label="Website" type="url" maxlength="100"></sl-input>
              <p><strong>Creating a profile (and any time you log in) will look like a
                 transaction from your wallet&mdash;but you will not be actually
                 charged.</strong> (This is done to support Ledger, which doesn't
                 allow offchain signing.)</p>
              <div class="buttons">
                <sl-button type="submit" variant="primary" loading={loading}>Create</sl-button>
              </div>
            </form>
          </div>

        </div>
      </Layout>
    }
  }
}
