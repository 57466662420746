import m from "mithril"

import api from "../api"
import utils from "../utils"
import {downtextToHtml} from "./editor/markup"

function htmlize(str) {
  let doc = downtextToHtml(str)
  return m.trust(doc.join(""))
}

export default function Post() {
  return {
    view: vnode => {
      let author = vnode.attrs.author
      let post = vnode.attrs.post
      let style = vnode.attrs.style
      let contents = []
      if (!author || !post?.content)
        return

      for (var c of post.content) {
        if (c.type === 'image') {
          let ce = contents.at(-1)
          if (ce?.at(-1)?.type === 'image') {
            ce.push(c)
          } else {
            contents.push([c])
          }
        } else {
          contents.push([c])
        }
      }

      return <li class={`${style}-post user-post`}>
        <sl-avatar image={author.avatar.url}
          label={`@${author.shortname}`} shape="rounded" onclick={() => m.route.set(`/@${author.username}`)}></sl-avatar>
        <div class="title">
          {author.nametag && <div class="nametag"><a href={`/@${author.username}`}>{author.nametag}</a></div>}
          <div class="username"><a href={`/@${author.username}`}>@{author.shortname}</a></div>
          {style == "brief" && <div class="age">{m(m.route.Link, {href: `/post/${post.id.substr(6)}`}, utils.timeAgo(post.created))}</div>}
        </div>
        <div class="content">
        {contents.map(ary => 
          <div class="content-group">
            {ary.map(c => <div class={`content-${c.type}`}>
                {c.type === 'image' && <img src={api.path(c.id)} />}
                {c.type === 'video' && <video src={api.path(c.id)} controls />}
                {c.type === 'text' && <p>{htmlize(c.source)}</p>}
              </div>)}
          </div>)}
          {style == "full" && <div class="published">{utils.timeFormat(post.created)}</div>}
        </div>
      </li>
    }
  }
}
