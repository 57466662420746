/*
 * Generated by the Waxeye Parser Generator - version 0.8.1
 * www.waxeye.org
 */

var waxeye = waxeye;
if (typeof module !== 'undefined') {
    // require from module system
    waxeye = require('../../waxeye');
}

function Parser() {}
Parser.prototype = new waxeye.WaxeyeParser(
{'Downtext': { mode: 1 /* NORMAL */, exp: { type: 5 /* STAR */, expr: { type: 1 /* NT */, name: 'Token' } } },
    'Token': { mode: 2 /* PRUNING */, exp: { type: 2 /* ALT */, exprs: [{ type: 1 /* NT */, name: 'Mention' }, { type: 1 /* NT */, name: 'Emoji' }, { type: 1 /* NT */, name: 'Link' }, { type: 10 /* ANY_CHAR */ }] } },
    'Mention': { mode: 1 /* NORMAL */, exp: { type: 3 /* SEQ */, exprs: [{ type: 11 /* CHAR */, char: '@' }, { type: 4 /* PLUS */, expr: { type: 12 /* CHAR_CLASS */, codepoints: [0x2d, [0x30, 0x39], [0x41, 0x5a], 0x5f, [0x61, 0x7a]] } }] } },
    'Emoji': { mode: 1 /* NORMAL */, exp: { type: 3 /* SEQ */, exprs: [{ type: 9 /* VOID */, expr: { type: 11 /* CHAR */, char: ':' } }, { type: 4 /* PLUS */, expr: { type: 12 /* CHAR_CLASS */, codepoints: [0x2d, [0x30, 0x39], 0x5f, [0x61, 0x7a]] } }, { type: 9 /* VOID */, expr: { type: 11 /* CHAR */, char: ':' } }] } },
    'Link': { mode: 1 /* NORMAL */, exp: { type: 3 /* SEQ */, exprs: [{ type: 6 /* OPT */, expr: { type: 1 /* NT */, name: 'Scheme' } }, { type: 1 /* NT */, name: 'Host' }, { type: 1 /* NT */, name: 'Tld' }, { type: 1 /* NT */, name: 'Path' }] } },
    'Scheme': { mode: 1 /* NORMAL */, exp: { type: 3 /* SEQ */, exprs: [{ type: 6 /* OPT */, expr: { type: 3 /* SEQ */, exprs: [{ type: 3 /* SEQ */, exprs: [{ type: 11 /* CHAR */, char: 'h' }, { type: 11 /* CHAR */, char: 't' }, { type: 11 /* CHAR */, char: 't' }, { type: 11 /* CHAR */, char: 'p' }] }, { type: 6 /* OPT */, expr: { type: 11 /* CHAR */, char: 's' } }, { type: 11 /* CHAR */, char: ':' }] } }, { type: 3 /* SEQ */, exprs: [{ type: 11 /* CHAR */, char: '/' }, { type: 11 /* CHAR */, char: '/' }] }] } },
    'Host': { mode: 1 /* NORMAL */, exp: { type: 4 /* PLUS */, expr: { type: 3 /* SEQ */, exprs: [{ type: 4 /* PLUS */, expr: { type: 12 /* CHAR_CLASS */, codepoints: [0x2d, [0x30, 0x39], [0x41, 0x5a], [0x61, 0x7a]] } }, { type: 11 /* CHAR */, char: '.' }] } } },
    'Tld': { mode: 1 /* NORMAL */, exp: { type: 4 /* PLUS */, expr: { type: 12 /* CHAR_CLASS */, codepoints: [0x2d, [0x30, 0x39], [0x41, 0x5a], [0x61, 0x7a]] } } },
    'Path': { mode: 1 /* NORMAL */, exp: { type: 6 /* OPT */, expr: { type: 3 /* SEQ */, exprs: [{ type: 11 /* CHAR */, char: '/' }, { type: 5 /* STAR */, expr: { type: 12 /* CHAR_CLASS */, codepoints: [0x21, 0x23, [0x25, 0x26], 0x2b, [0x2d, 0x39], 0x3b, 0x3d, [0x3f, 0x5a], 0x5f, [0x61, 0x7a], 0x7e] } }] } } }}
, 'Downtext');

// Add to module system
if (typeof module !== 'undefined') {
    module.exports.Parser = Parser;
}
