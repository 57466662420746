import {baseKeymap} from "prosemirror-commands"
import {history} from "prosemirror-history"
import {inputRules} from "prosemirror-inputrules"
import {EditorView} from "prosemirror-view"
import {EditorState} from "prosemirror-state"
import {keymap} from "prosemirror-keymap"
import {schema, schemaInputRules, shortToEmoji, defaultParser, defaultSerializer} from "./markup"
import {buildKeymap} from "./keymap"
import placeholder from "./placeholder"

export class ProseMirrorView {
  constructor(target, content, placeholderText, onInput) {
		let hist = history()
    let plugins = [/* placeholder(placeholderText),*/ keymap(buildKeymap(schema)),
			keymap(baseKeymap), inputRules(schemaInputRules), hist]
    let doc = defaultParser(content)
    this.view = new EditorView(target, {
      handleKeyDown: onInput,
      handleDrop: onInput,
      handlePaste: onInput,
      state: EditorState.create({
        schema,
        doc,
        plugins
      })
    })
  }

  insertEmoji(em) {
    let attrs = shortToEmoji(em.id)
    this.view.dispatch(this.view.state.tr.replaceSelectionWith(schema.node("emoji", attrs)))
  }
  get content() {
    return defaultSerializer(this.view.state.doc)
  }
  focus() { this.view.focus() }
  destroy() { this.view.destroy() }
}
