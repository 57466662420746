import m from "mithril"
import api from "../api"
import Homeslice from '../homeslice'
import Solana from '../solana'

import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'
import '@shoelace-style/shoelace/dist/components/menu/menu.js'
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js'

export default function Wallet() {
  Homeslice.mithril(m, process.env.SOL_CHAIN)

  let verifySignIn = async () => {
    let user = await api.userDetails(api.wallet.address)
    if (user?.details) {
      await api.signedTxLogin()
    } else {
      // No required details - setup the acccount
      m.route.set("/welcome")
    }
  }
  let walletConnect = async name => {
    for (let w of Homeslice.wallets) {
      if (w.name == name) {
        let account = await Homeslice.connect(w)
        api.setWallet(name, account.address)
        let token = api.getToken(account)
        if (token) {
          api.setToken(token)
          m.redraw()
        } else {
          await verifySignIn()
        }
        m.redraw()
        break
      }
    }
  }
  let walletDisconnect = () => {
    api.expireToken()
    m.redraw()
  }

  return {
    view: vnode => <div class="wallet">
      {api.wallet ?
        <sl-dropdown>
          <sl-button slot="trigger" caret>
             <sl-icon slot="prefix" name="wallet2"></sl-icon>
             {api.smallAddress(api.wallet.address)}
          </sl-button>
          <sl-menu>
            {api.token ? <>
                <sl-menu-item onclick={() => m.route.set("/")}>
                  <sl-icon slot="prefix" name="house"></sl-icon>
                  Home
                </sl-menu-item>
                <sl-menu-item onclick={() => m.route.set("/profile")}>
                  <sl-icon slot="prefix" name="person-square"></sl-icon>
                  My Profile
                </sl-menu-item>
                <sl-menu-item onclick={() => m.route.set("/credits")}>
                  <sl-icon slot="prefix" name="credit-card"></sl-icon>
                  Buy Credits 
                </sl-menu-item>
              </> :
              <sl-menu-item onclick={verifySignIn}>
                <sl-icon slot="prefix" name="exclamation-square"></sl-icon>
                Verify Sign In
              </sl-menu-item>}
            <sl-menu-item onclick={walletDisconnect}>
              <sl-icon slot="prefix" name="box-arrow-left"></sl-icon>
              Disconnect
            </sl-menu-item>
          </sl-menu>
        </sl-dropdown> :

        <sl-dropdown onsl-select={e => walletConnect(e.detail.item.value)}>
          <sl-button slot="trigger" caret>
            <sl-icon slot="prefix" name="wallet2"></sl-icon>
            Connect Wallet
          </sl-button>
          <sl-menu>
          {Homeslice.wallets.length > 0 ? Homeslice.wallets.map(wallet =>
            <sl-menu-item value={wallet.name}>
              <sl-icon slot="prefix" src={wallet.icon}></sl-icon>
              {wallet.name}
            </sl-menu-item>
          ) : 
            <sl-menu-item disabled>
              No Solana wallet?
            </sl-menu-item>
          }
          </sl-menu>
        </sl-dropdown>
      }
    </div>
  }
}
