class Timeline {
  constructor (worker = null) {
    this.worker = worker
    this.callback = null
  }

  message(obj) {
    if (this.worker) {
      this.worker.port.postMessage(obj)
    } else {
      perform(obj, this.callback)
    }
  }

  start(fn) {
    if (this.worker) {
      this.worker.port.addEventListener('message', ev => fn(ev.data))
      this.worker.port.start()
    } else {
      this.callback = fn
    }
  }
}

var SOURCE = null
function perform(obj, fn) {
  let {action, url, token} = obj
  if (action == 'login') {
    if (!SOURCE) {
      SOURCE = new EventSource(url)
      SOURCE.addEventListener('message', msg => {
        fn({lastEventId: msg.lastEventId, data: msg.data})
      })
    }
  } else if (action == 'logout') {
    close()
  }
}

function close() {
  if (SOURCE) {
    SOURCE.close()
    SOURCE = null
  }
}

function setup() {
  try {
    let worker = new SharedWorker(new URL('worker.js', import.meta.url))
    return new Timeline(worker)
  } catch {
    return new Timeline()
  }
}

export default {perform, close, setup}
