import m from "mithril"

import Layout from "./layout"

export default function AboutPage() {
  return {
    view: vnode => {
      return <Layout>
        <div id="page">
          <p><strong>stiiks.social</strong> is a microblogging app for the
          Solana blockchain - specifically for the Stiiks and Sunnies NFT sets.
          (Some other Suns Studio assets can also be used as banners.)</p>

          <p>The cool thing about this app is that if this site goes down, the
          network contents still remain. User metadata is stored on Solana; post
          data is currently kept on IPFS. My intent is to open the standard up
          to others, depending on how this experiment goes.</p>

          <p>Anyway, this is my Christmas present to the Stiiks community. Stiiks
          has long been a joke in the Suns community - but it's also a leaderless
          0% royalty NFT, perfect as a generic, tradable passcard. Made sense to
          use it here.</p>
        </div>
      </Layout>
    }
  }
}
