import {Connection, PublicKey, Transaction} from "@solana/web3.js"
import {getWallets} from '@wallet-standard/core'

const H = {
  m: null,
  wallets: [],
  chain: "solana:mainnet",
  //
  // Other methods call H, so no need to call directly.
  //
  initialize: (chain = null) => { 
    H.initialize = () => {}
    const { get, on } = getWallets()
    if (chain) {
      H.chain = chain
    }

    //
    // Load all Solana wallets using wallet-standard
    //
    H.loadWallets = () => {
      H.wallets = get().filter(wallet => wallet.chains.includes("solana:mainnet"))
      if (H.m) H.m.redraw()
    }

    H.loadWallets()
    on('register',   H.loadWallets)
    on('unregister', H.loadWallets)
  },

  //
  // Attach Mithril so that redraw events can be sent
  //
  mithril: (m, chain = null) => {
    H.m = m
    H.initialize(chain)
  },

  //
  // List accounts within a wallet
  //
  connect: async wallet => {
    let {accounts} = await wallet.features["standard:connect"].connect()
    return (accounts || [])[0]
  },

  //
  // Lookup the wallet and account object from strings
  //
  lookup: async (walletName, accountAddress) => {
    let account = null
    console.log(H.wallets)
    let wallet = H.wallets.find(w => w.name === walletName)
    if (wallet) {
      let selectedAccount = await H.connect(wallet)
      if (selectedAccount.address === accountAddress) {
        account = selectedAccount
      }
    }
    return {account, wallet}
  },

  //
  // Sign a message using the user's wallet
  // 
  sign: async (walletName, accountAddress, message) => {
    let {wallet, account} = await H.lookup(walletName, accountAddress)
    let outputs = await wallet.features["solana:signMessage"].signMessage({account, message})
    let {signature} = outputs[0]
    return signature
  },

  //
  // Sign a transaction using the user's wallet
  // 
  signTransaction: async (walletName, accountAddress, transaction) => {
    let {wallet, account} = await H.lookup(walletName, accountAddress)
    let outputs = await wallet.features["solana:signTransaction"].
      signTransaction({account, transaction})
    let {signedTransaction} = outputs[0]
    return signedTransaction
  },

  //
  // Send a transaction using the user's wallet
  // 
  sendTransaction: async (walletName, accountAddress, transaction) => {
    let {wallet, account} = await H.lookup(walletName, accountAddress)
    let outputs = await wallet.features["solana:signAndSendTransaction"].
      signAndSendTransaction({account, transaction, chain: H.chain})
    let {signature} = outputs[0]
    return signature
  }
}

export default H
