import m from "mithril"
import api from "../api"
import tasks from "../tasks"

import Editor from "./editor"
import Solana from "../solana"

export default function NewPost(vnode) {
  let user = null

  return {
    oninit: async vnode => {
      const sol = new Solana()
      let profile = await sol.onchainProfile(api.wallet.address)
      if (profile) {
        user = await api.storageProfile(profile.address)
      }
      m.redraw()
    },

    view: vnode => {
      let cid = api.profile.sub
      let savePost = async post => {
        let {id} = await tasks.savePost(post)
        if (id) {
          if (vnode.attrs.onsaved) {
            vnode.attrs.onsaved({id})
          }
          return true
        } else {
          return false
        }
      }

      if (!user)
        return
      return <Editor username={user.username} image={user.avatar.url}
        onsave={savePost} />
    }
  }
}
