import { DecorationSet, Decoration } from 'prosemirror-view'
import { Plugin } from 'prosemirror-state'

export default text => new Plugin({
  props: {
    decorations (state) {
      const doc = state.doc

      if (doc.childCount > 1 ||
        (doc.childCount === 1 &&
        (!doc.firstChild.isTextblock ||
          doc.firstChild.content.size > 0))) return

      const placeHolder = document.createElement('span')
      placeHolder.classList.add('placeholder')
      placeHolder.textContent = text

      return DecorationSet.create(doc, [Decoration.widget(1, placeHolder)])
    }
  }
})
