import {
  tab_panel_default
} from "../../chunks/chunk.IPER4AGF.js";
import "../../chunks/chunk.DHQRDM5R.js";
import "../../chunks/chunk.JTIOSPX3.js";
import "../../chunks/chunk.Q6ASPMKT.js";
import "../../chunks/chunk.JS655M6J.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.MAD5PUM2.js";
export {
  tab_panel_default as default
};
