import {
  SlProgressRing
} from "./chunk.AASXFDPD.js";

// src/components/progress-ring/progress-ring.ts
var progress_ring_default = SlProgressRing;
SlProgressRing.define("sl-progress-ring");

export {
  progress_ring_default
};
