import base58 from 'bs58'
import m from 'mithril'
import eventsrc from 'eventsource'
import { applyPatch } from 'fast-json-patch/index.js'
import { jsonDateParser } from "json-date-parser"
import jwt from 'jwt-decode'
import lru from 'lru-cache'
import u from 'umbrellajs'
import normalizeUrl from 'normalize-url'

import Homeslice from './homeslice'
import Solana, {downbadsCloseProfile, downbadsUserProfile, onchainMemo} from './solana'
import {PublicKey, Transaction} from "@solana/web3.js"
import utils from './utils'

import Timeline from './timeline'
const T = Timeline.setup()

var commonWait = 10 * 60
var longWait = 60 * 60
var cache = new lru({max: 500})

function extract(xhr) {
  return JSON.parse(xhr.responseText, jsonDateParser)
}

var api = {
  source: null,
  token: null,
  timeline: [],

  path(url) {
    return process.env.WEB_API + url.substr(1)
  },

  fetch(url) {
    return fetch(process.env.WEB_API + url,
      {redirect: "manual", headers: {"Authorization": "Bearer " + this.token}})
  },

  fetch2(url, opts) {
    return new Promise((resolve, reject) =>
      fetch(url, opts).
        then(resp => resp.text().then(txt => {
          let obj = {}
          if (txt && resp.ok) {
            obj = JSON.parse(txt, jsonDateParser)
          }
          if (resp.ok || resp.status === 304) {
            resolve(obj)
          } else {
            reject(obj)
          }
          m.redraw()
        })))
  },

  request(method, path, body = null, token = null) {
    let headers = {}
    if (body && !(body instanceof FormData)) {
      headers = {"Content-Type": "application/json",
        "Accept": "application/json"}
      body = JSON.stringify(body)
    }
    if (token) {
      headers["Authorization"] = "Bearer " + token
    }
    return this.fetch2(process.env.WEB_API + path,
      {method, headers, body})
  },

  post(path, body = null, token = null) {
    return this.request("POST", path, body, token)
  },

  put(path, body = null, token = null) {
    return this.request("PUT", path, body, token)
  },

  get(path, token = null) {
    return this.request("GET", path, null, token)
  },

  delete(path, token = null) {
    return this.request("DELETE", path, null, token)
  },

  // form submission convenience
  // pass in the form or the submit event
  form(ev, method, path, token = null) {
    let body
    let form = ev
    if (ev.preventDefault) {
      ev.preventDefault()
      form = ev.target
    }
    if (form instanceof HTMLFormElement) {
      body = new FormData(form)
    } else {
      body = new FormData()
      for (let key in form) {
        body.append(key, form[key])
      }
    }
    let headers = {}
    if (token) {
      headers["Authorization"] = "Bearer " + token
    }
    return this.fetch2(process.env.WEB_API + path,
      {method, headers, body})
  },

	incomingMessage({lastEventId, data}) {
		if (lastEventId && data) {
			let i = 0
			let p = JSON.parse(data, jsonDateParser)
			for (i = 0; i < api.timeline.length; i++) {
				let obj = api.timeline[i]
				if (obj.id == p.id) {
					return
				}
				if (obj.created < p.created) {
					break
				}
			}
			api.timeline.splice(i, 0, p)
			api.populatePost(p)
		}
		// if (msg.event === 'patch' && msg.id && msg.data) {
		//   let doc = cache.get(msg.id)
		//   let inbox = JSON.parse(msg.data)
		//   // TODO: check inbox.user against block/priority list
		//   // TODO: check inbox.subs against subscription list
		//   let {newDocument} = applyPatch(doc, inbox.patch)
		//   cache.set(msg.id, newDocument)
		// }
  },

  // Specific calls used again and again
  getGroups() {
    return this.get("groups", this.token, longWait)
  },

  // Posts
  savePost(obj) {
    return this.post("posts", obj, this.token)
  },

  // Profile
  setWallet(software, address) {
    this.wallet = {software, address}
    localStorage.setItem('wallet', JSON.stringify(this.wallet))
  },

  getWallet() {
    let str = localStorage.getItem('wallet')
    this.wallet = str ? JSON.parse(str) : null
    return this.wallet
  },

  setToken(token, save = false) {
    if (token) {
      try {
        this.token = token
        this.profile = jwt(token)
				let query = {"auth": "Bearer " + token}
				let url = process.env.WEB_API + "stream?" +
					(new URLSearchParams(query)).toString()
				T.message({action: 'login', token, url})
        if (save) {
          let addr = this.profile.sub
          localStorage.setItem(`token:${addr}`, token)
        }
      } catch (e) {}
      return token
    }
  },

  getToken(wallet) {
    return localStorage.getItem(`token:${wallet.address}`)
  },

  expireToken() {
    this.wallet = null
    this.token = null
    this.profile = null
		T.message({action: 'logout'})
    localStorage.removeItem('wallet')
  },

  startProof(addr) {
    return this.get(`proof/${addr}`)
  },

  submitProof(nonce, key, signature, profile = null) {
    let body = new FormData()
    body.append(key, new Blob([signature], {type: "application/octet-stream"}))
    if (profile) {
      body.append("profile", new Blob([JSON.stringify(profile)], {type: "application/json"}))
    }

    return this.fetch2(process.env.WEB_API + `proof/${nonce}`, {method: "POST", body}).
      then(obj => this.setToken(obj.token, true))
  },

  checkCredits(signature) {
    let headers = {"Authorization": "Bearer " + this.token}
    return this.fetch2(process.env.WEB_API + `credits`,
      {headers, method: "POST", body: signature})
  },

  //
  // Login, update profile, get a JWT
  //
  async signedLogin(state = null) {
    const {nonce} = await this.startProof(this.wallet.address)
    const message = new TextEncoder().encode(nonce)
    let signature = await Homeslice.sign(this.wallet.software, this.wallet.address, message)
    await api.submitProof(nonce, "signature", signature, state)
  },

  //
  // Login, update profile, get a JWT
  //
  async signedTxLogin(state = null) {
    let walletKey = new PublicKey(this.wallet.address)
    const {nonce} = await this.startProof(this.wallet.address)
    let sol = new Solana()
    let recentBlockhash = await sol.getLatestBlockhash()
    let tx = new Transaction({feePayer: walletKey,
      recentBlockhash})
    tx.add(await onchainMemo(nonce))
    let transaction = tx.serialize({ requireAllSignatures: false })
    let signature = await Homeslice.signTransaction(this.wallet.software, this.wallet.address, transaction)
    await api.submitProof(nonce, "signature", signature, state)
  },

  //
  // Close a user account
  //
  async closeAccount() {
    let walletKey = new PublicKey(this.wallet.address)
    let sol = new Solana()
    let recentBlockhash = await sol.getLatestBlockhash()
    let tx = new Transaction({feePayer: walletKey,
      recentBlockhash})
    tx.add(await downbadsCloseProfile(walletKey))
    let rx = await Homeslice.sendTransaction(this.wallet.software, this.wallet.address,
      tx.serialize({ requireAllSignatures: false }))
  },

  //
  // Buy a single batch of 10k credits, update profile, get a JWT
  //
  async payAccount(avatar, ipnsAddress, creds) {
    let walletKey = new PublicKey(this.wallet.address)
    let avatarKey = new PublicKey(avatar)
    let sol = new Solana()
    let tx = new Transaction({feePayer: walletKey,
      recentBlockhash: await sol.getLatestBlockhash()})
    tx.add(await downbadsUserProfile(walletKey, avatarKey, ipnsAddress, creds, 0))
    let rx = await Homeslice.sendTransaction(this.wallet.software, this.wallet.address,
      tx.serialize({ requireAllSignatures: false }))
    return await api.checkCredits(rx)
  },

  async userDetails(addr) {
    try {
      return await this.get("users/" + addr)
    } catch {
      return null
    }
  },

  smallAddress(str) {
    return str.slice(0, 6) + "/" + str.slice(-4)
  },

  shortname(profile) {
    return profile.username || this.smallAddress(profile.wallet)
  },

  username(profile) {
    return profile.username || profile.wallet
  },

  nametag(profile) {
    return profile.nametag || this.username(profile)
  },

  totalCreds(profile) {
    return profile ? (profile.creds + profile.earned) - profile.spent : 0
  },

  isAccountActive() {
    return api.profile?.sub ? true : false
  },
  
  isAllAccess() {
    return api.profile?.access[0] ? true : false
  },


  //
  // File storage API calls
  //
  storageGet(addr) {
    return this.get(addr.substr(1))
  },

  populatePost(post) {
    api.storageGet(post.id + "/index.json").then(fullPost => {
      Object.assign(post, fullPost)
      m.redraw()
    })
  },

  normalizeUser(user) {
    let url
    if (user.url) {
      try {
        url = new URL(normalizeUrl(user.url))
      } catch {}
    }
    return {
      shortname: api.shortname(user),
      username: api.username(user),
      nametag: api.nametag(user),
      url,
      bio: user.bio,
      avatar: user.avatar
    }
  },

  timelineSort(summaries) {
    return summaries.sort((a, b) => b.created - a.created)
  },

  async storageProfile(addr) {
    let ipns = await api.storageGet(addr)
    if (ipns) {
      let banner
      if (ipns.details.banner) {
        let siz = await utils.measure(m("img", {src: ipns.details.banner.url}))
        banner = {style: siz[0] > siz[1] ? "top" : "side", url: ipns.details.banner.url}
      } else {
        banner = {style: "side"}
      }

      let user = api.normalizeUser(ipns.details)
      user.banner = banner
      user.timeline = api.timelineSort(ipns.timeline)
      return user
    }
  },

  ready(fn = null) {
    document.addEventListener("DOMContentLoaded", () => {
      if (fn) {
        fn()
      }
    })
  }
}

T.start(msg => api.incomingMessage(msg))

let wallet = api.getWallet()
if (wallet) {
  api.setToken(api.getToken(wallet))
}

export default api
