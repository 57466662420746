import m from "mithril"
import api from "../api"
import Solana from '../solana'
import utils from '../utils'
import {PublicKey} from "@solana/web3.js"

import Layout from "./layout"

export default function SetupBanner(vnode) {
	let state
  let tokens
  let selectBanner = (address, url) => {
    utils.workingState({banner: (url ? {address, url} : null)})
		m.route.set("/setup/finish")
	}

  return {
    oninit: async vnode => {
			state = utils.workingState()
      const walletKey = new PublicKey(api.wallet.address)
			const sol = new Solana()
      tokens = await sol.nftsOwnedBy(walletKey)
      if (tokens.length > 0) {
        tokens = await sol.loadTokenAccounts(tokens)
        tokens = tokens.filter(t => sol.isQualifiedBanner(t))
      }
			if (tokens.length > 0) {
				tokens = await sol.loadTokenMetadata(tokens)
			}
			if (state.banner?.address) {
				let idx = tokens.findIndex(t => t.mint === state.banner.address)
				if (idx >= 0) {
					let sel = tokens.splice(idx, 1)
					tokens = sel.concat(tokens)
				}
			}
			m.redraw()
    },

    view: vnode => {
      let uniqueTokens = {}
      return <Layout>
        <div id="page">
          <h1>Select a Banner</h1>

					{tokens ? <>
						<p>NFTs from RGB's banner collections can be used on your
              profile.</p>
            {tokens.length === 0 &&
              <p>Unfortunately, there don't seem to be any qualifying banners
                 in this wallet.</p>}
            <div class="buttons">
              <sl-button variant="primary" onclick={selectBanner}>Skip This</sl-button>
            </div>

						<div>
						{tokens.map(token => {
              if (uniqueTokens[token.nft.name])
                return
              let files = token.nft.properties.files
              let image = files.length > 1 ? files[1].uri : token.nft.image
              uniqueTokens[token.nft.name] = true

							return <div class={`banner image-select ` + (state?.banner?.address && state.banner.address === token.mint && 'image-current')}>
                <p>{token.nft.name}</p>
								<img
									slot="image"
									src={image}
									alt={token.nft.name}
                  onclick={() => selectBanner(token.mint, image)}
								/>
              </div>

						})}
						</div>

					</> : <>
						<p>Looking for Solsunsets and Suns Saga NFTs.</p>	
						<sl-spinner style="font-size: 3rem; --indicator-color: deeppink; --track-color: pink;"></sl-spinner>
					</>
				}
        </div>
      </Layout>
    }
  }
}
