import m from "mithril"
import api from "../api"
import utils from "../utils"
import Solana from "../solana"

import Editor from "../components/editor"
import Wallet from "../components/wallet"
import Post from "../components/post"

export default function PostPage(vnode) {
  let cid = vnode.attrs.cid
  let author
  let post

  return {
    oninit: async vnode => {
      post = await api.get(`ipfs/${cid}/index.json`)
      let sol = new Solana()
      let profile = await sol.onchainProfile(post.author.wallet)
      if (profile) {
        author = await api.storageProfile(profile.address)
      }
      m.redraw()
    },

    view: vnode => {
      if (post) {
        return <div id="page">
          <Wallet />
          <a href="/"><img src={new URL("../images/stiiks-social.png", import.meta.url)} style="width:128px" /></a>
          <ol class="focus">
            <Post author={author} post={post} style="full" />
          </ol>
        </div>
      }
    }
  }
}
