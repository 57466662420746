import m from "mithril"

import Wallet from "../components/wallet"

export default function Layout(vnode) {
  return {
    view: vnode => {
       return <>
         <Wallet />
         {vnode.children}
       </>
    }
  }
}
