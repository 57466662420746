import m from "mithril"
import api from "./api"
import IndexPage from "./views/index"
import AboutPage from "./views/about"
import ProfilePage from "./views/profile"
import UserPage from "./views/user"
import NewPostPage from "./views/new-post"
import PostPage from "./views/post"
import SetupAvatar from "./views/setup-avatar"
import SetupBanner from "./views/setup-banner"
import SetupFinish from "./views/setup-finish"
import WelcomePage from "./views/welcome"
import CreditsPage from "./views/credits"

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js'
import '@shoelace-style/shoelace/dist/themes/light.css'
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/card/card.js'
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'
import '@shoelace-style/shoelace/dist/components/input/input.js'
import '@shoelace-style/shoelace/dist/components/menu/menu.js'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js'
import '@shoelace-style/shoelace/dist/components/option/option.js'
import '@shoelace-style/shoelace/dist/components/progress-ring/progress-ring.js'
import '@shoelace-style/shoelace/dist/components/select/select.js'
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton.js'
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js'
import '@shoelace-style/shoelace/dist/components/tab/tab.js'
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js'
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js'
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js'
import './css/downbads.scss'
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.3.0/dist/');

//
// Intercept any links that aren't being routed through m.route.set
//
api.ready(() => {
  document.addEventListener(`click`, e => {
    const origin = e.target.closest(`a`)
    if (origin && origin.href.startsWith(window.location.origin)) {
      e.preventDefault()
      m.route.set(origin.pathname)
    }
  })
})

var Auth = page => {
  return {onmatch: () =>
    api.profile ? (api.profile?.sub ? page : m.route.set("/welcome")) : m.route.set("/") }
}

var Connected = page => {
  return {onmatch: () => api.wallet ? page : m.route.set("/")}
}

m.route.prefix = ""
m.route(document.getElementById("downbads"), "/", {
  "/": IndexPage,
  "/clear": {view: () => localStorage.clear()},
  "/credits": Auth(CreditsPage),
  "/profile": Auth(ProfilePage),
  "/profile/avatar": Auth(SetupAvatar),
  "/profile/banner": Auth(SetupBanner),
  "/profile/finish": Auth(SetupFinish),
  "/welcome": Connected(WelcomePage),
  "/setup": Connected(SetupAvatar),
  "/setup/banner": Connected(SetupBanner),
  "/setup/finish": Connected(SetupFinish),
  "/about": AboutPage,
  "/post": NewPostPage,
  "/post/:cid": PostPage,
  "/*:group": UserPage,
  "/@:username": UserPage
})
