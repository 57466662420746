import m from "mithril"
import NewPost from "../components/new-post"
import Layout from "./layout"

export default function NewPostPage(vnode) {
  let onsaved = ({id}) => m.route.set('/post/:cid', {cid: id.substr(6)})
  return {
    view: vnode => {
      return <Layout>
        <div id="page">
          <NewPost onsaved={onsaved} />
        </div>
      </Layout>
    }
  }
}
