import {lift, liftEmptyBlock, wrapIn, setBlockType, chainCommands, toggleMark, exitCode,
        selectParentNode, selectNodeForward, splitBlock} from "prosemirror-commands"
import {undo, redo} from "prosemirror-history"

const mac = typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false

// :: (Schema, ?Object)  Object
// Inspect the given schema looking for marks and nodes from the
// basic schema, and if found, add key bindings related to them.
// This will add:
//
// * **Mod-b** for toggling [strong](#schema-basic.StrongMark)
// * **Mod-i** for toggling [emphasis](#schema-basic.EmMark)
// * **Mod-`** for toggling [code font](#schema-basic.CodeMark)
// * **Enter** to split a non-empty textblock in a list item while at
//   the same time splitting the list item
// * **Mod-Enter** to insert a hard break
// * **Mod-_** to insert a horizontal rule
// * **Backspace** to undo an input rule
// * **Escape** to `selectParentNode`
//
// You can suppress or map these bindings by passing a `mapKeys`
// argument, which maps key names (say `"Mod-B"` to either `false`, to
// remove the binding, or a new key name string.
export function buildKeymap(schema, mapKeys) {
  let keys = {}, type
  function bind(key, cmd) {
    if (mapKeys) {
      let mapped = mapKeys[key]
      if (mapped === false) return
      if (mapped) key = mapped
    }
    keys[key] = cmd
  }


  bind("Mod-z", undo)
  bind("Shift-Mod-z", redo)
  if (!mac) bind("Mod-y", redo)

  bind("Escape", selectParentNode)

  // if (type = schema.marks.strong) {
  //   bind("Mod-b", toggleMark(type))
  //   bind("Mod-B", toggleMark(type))
  // }
  // if (type = schema.marks.em) {
  //   bind("Mod-i", toggleMark(type))
  //   bind("Mod-I", toggleMark(type))
  // }
  // if (type = schema.marks.code)
  //   bind("Mod-`", toggleMark(type))
  // if (type = schema.nodes.blockquote)
  //   bind("Ctrl->", wrapIn(type))

  if (type = schema.nodes.hard_break) {
    let br = type, cmd = chainCommands(exitCode, (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView())
      return true
    })
    bind("Enter", cmd)
    bind("Mod-Enter", cmd)
    bind("Shift-Enter", cmd)
    if (mac) bind("Ctrl-Enter", cmd)
  }

  return keys
}
