import {PublicKey} from "@solana/web3.js"

export default {
  SYSTEM_ACCOUNT: new PublicKey("11111111111111111111111111111111"),
  TOKEN_PROGRAM_ID: new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
  TOKEN_ACCOUNT_PROGRAM_ID: new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"),
  METADATA_PROGRAM_ID: new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"),
  METADATA_PREFIX: "metadata",
  PROGRAM_ID: new PublicKey("J2FNjA3B3i9Sau4kHMAmPuEJzNS3Hkwond8jauVp59bH"),
  MEMO_PROGRAM_ID: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
  PROG_SEED: 'downbads',
  USER_SEED: 'downbadz',
  OWNER_ACCOUNT: new PublicKey("6XnKPLuabF1CpDb89qJHFUwkR1kPaUZTYVKXPEVoJHfU"),
  EMPTY_GIF: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
  STIIKS_SOL: new PublicKey("StiiksKYAyB6wTAToE2Evp9KYSH1FBPg7xi45JeCskD")
}
