import m from "mithril"
import api from "../api"
import Solana from '../solana'
import utils from "../utils"
import {PublicKey} from "@solana/web3.js"

import Layout from "./layout"

export default function SetupAvatar(vnode) {
	let state
  let tokens
  let access = []
  let selectAvatar = (address, url) => {
    utils.workingState({avatar: {address, url}, access})
		m.route.set("/setup/banner")
	}

  return {
    oninit: async vnode => {
			state = utils.workingState()
      const walletKey = new PublicKey(api.wallet.address)
			const sol = new Solana()
      tokens = await sol.nftsOwnedBy(walletKey)
      if (tokens.length > 0) {
        tokens = await sol.loadTokenAccounts(tokens)
        tokens = tokens.filter(t => sol.isQualifiedNft(t))
        // Pass Sunnies list to the service
        access = tokens.filter(t => sol.isAllAccessNft(t)).
          map(token => token.mint).
          filter(addr => typeof(addr) == "string")
      }
			if (tokens.length > 0) {
				tokens = await sol.loadTokenMetadata(tokens)
			}
			if (state.avatar?.address) {
				let idx = tokens.findIndex(t => t.mint === state.avatar.address)
				if (idx >= 0) {
					let sel = tokens.splice(idx, 1)
					tokens = sel.concat(tokens)
				}
			}
			m.redraw()
    },

    view: vnode => {
      return <Layout>
        <div id="page">
          <h1>Pick Your Avatar</h1>

					{tokens ? 
            (tokens.length > 0 ? <>
              <p>Time to select which Stiiks or Sunnies NFT to use for your PFP.
                You can change this at any time - your account is attached to this
                wallet, not to your PFP.</p>

              <div class="select-avatar">
              {tokens.map(token =>
                <div class={`avatar image-select ` + (state?.avatar?.address && state.avatar.address === token.mint && 'image-current')}>
                  <p>{token.nft.name.replace(/\(.+?\)/, '')}</p>
                  <img
                    slot="image"
                    src={token.nft.image}
                    alt={token.nft.name}
                    onclick={() => selectAvatar(token.mint, token.nft.image)}
                  />
                </div>
              )}
              </div>

              <br clear="all" />
              <p>If you give away the NFT, you're not giving away your Stiiks.Social
                account as well. However, you do need one of these NFTs in the wallet
                to login.</p>
            </> :
            <p>Can't find a Stiiks or Sunnies NFT in this wallet.</p>) :
					<>
						<p>Looking for Sunnies and Stiiks NFTs.</p>	
						<sl-spinner style="font-size: 3rem; --indicator-color: deeppink; --track-color: pink;"></sl-spinner>
					</>
				}
        </div>
      </Layout>
    }
  }
}
