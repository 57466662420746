import m from "mithril"
import api from "../api"
import Post from "../components/post"
import Wallet from "../components/wallet"
import NewPost from "../components/new-post"

export default function IndexPage() {
  return {
    view: vnode => {
      return <div>
        <Wallet />
        {api.profile ?
          <div id="page">
            <a href="/"><img src={new URL("../images/stiiks-social.png", import.meta.url)} style="width:128px" /></a>
            <NewPost />
            <sl-tab-group>
              <sl-tab slot="nav" panel="all">Everyone</sl-tab>

              <sl-tab-panel name="all">
                <ol class="timeline">
                  {api.timeline.map(post => typeof(post.author) == 'object' &&
                    <Post author={api.normalizeUser(post.author)} post={post} style="brief" />
                  )}
                </ol>
              </sl-tab-panel>
            </sl-tab-group>
          </div> :
          <div id="page">
            <p style="text-align: center"><img src={new URL("../images/stiiks-social.png", import.meta.url)} /></p>
            <div class="narrow">
              <p>Ah, of course, Stiiks has its own decentralized social network.</p>
              <ul>
                <li>Profiles stored on SOL.</li>
                <li>Content stored on IPFS.</li>
                <li>Everyone follows everyone. (Immediate Stiik-to-Stiik communication.)</li>
              </ul>
              <p>Connect a wallet above to proceed. <img style="height: 1.3em" src={new URL("../images/tystiiks.webp", import.meta.url)} /></p>
            </div>
          </div>}
      </div>
    }
  }
}
