import m from "mithril"
import u from 'umbrellajs'
import debounce from 'debounce'
import api from "../api"
import utils from "../utils"
import Solana from "../solana"
import emoji from "./editor/emoji"
import { ProseMirrorView } from "./editor/prose"

import { Picker } from 'emoji-mart'
import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js'

//
// Cost is 0.00001 SOL per 100k (plus 0.0001 for the json)
// This function should avoid floating point math
//
function storageFee(len, images, video) {
  let b = 2, fmt = "0B"
  let siz = images.reduce((v, img) => img.size + v, len) + (video?.size || 0)
  let cost = api.isAllAccess() ? 0 : (Math.floor(siz / 100000) + 10)
  if (+siz) {
    const c=0>b?0:b,d=Math.floor(Math.log(siz)/Math.log(1024))
    fmt = `${parseFloat((siz/Math.pow(1024,d)).toFixed(c))}${["B","K","M","G","T","P","E","Z","Y"][d]}`
  }

  // let strCost = cost.toString().padStart(5, "0")
  // return {formattedSize: fmt, estimatedCost: strCost.slice(0, -4) + "." + strCost.slice(-4) + " SOL"}
  return {formattedSize: fmt, estimatedCost: `${cost} CRED`}
}

export default function Editor() {
  let user
  let images = []
  let video = null
  let prose
  let picker
  let onEmojiSelect = e => {
    toggleEmojiPicker(e)
    prose.insertEmoji(e)
  }
  let toggleEmojiPicker = e => {
    if (picker) {
      picker.style.display = picker.style.display ? "" : "none"
      return
    }

    let emojis = []
    for (var id in emoji.local) {
      let src = emoji.local[id].pathname
      emojis.push({id, name: id, keywords: ['suns'], skins: [{src}]})
    }
    picker = new Picker({onEmojiSelect, set: 'twitter',
      categories: ['frequent', 'suns', 'people', 'animals', 'foods', 'activity', 'places', 'objects', 'symbols', 'flags'],
      categoryIcons: {suns: {src: emoji.local['heh'].pathname}},
      custom: [{id: 'suns', name: 'Suns', emojis}], data: async () =>
      {
        const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data/sets/14/twitter.json')
        return response.json()
      }
    })
    document.getElementById("emoji-anchor").appendChild(picker)
  }
  let attachImage = e => {
    let input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.multiple = 'multiple'
    input.onchange = async e => { 
      let files = await utils.imgblat(e.target.files)
      images = images.concat(files.images)
      video = null
      m.redraw()
    }
    input.click()
  }
  let setupProse = ele => {
    prose = new ProseMirrorView(ele, "", "What's Stiikin?", debounce(() => m.redraw(), 200))
  }
  let attachVideo = e => {
    let allow = ['mp4', 'mov', 'ogm', 'ogv', 'ogg', 'webm', 'avi']
    let input = document.createElement('input')
    input.type = 'file'
    input.accept = 'video/*'
    input.onchange = async e => { 
      for (let src of e.target.files) {
        let ext = src.name?.split(".").pop()
        if (allow.includes(ext)) {
          images = []
          video = src
          video.objectURL = URL.createObjectURL(src)
          //console.log(video)
          break
        }
      }
      m.redraw()
    }
    input.click()
  }

  let removeImage = index => {
    images.splice(index, 1)
    m.redraw()
  }
  
  let showDialog = b => {
    let dialog = document.querySelector('sl-dialog')
    if (b) dialog.show()
    else   dialog.hide()
    return false
  }

  return {
    oncreate: async vnode => {
      user = await api.userDetails(api.profile.sub)
      m.redraw()
    },

    view: vnode => {
      let content = prose?.content || ""
      let textlen = new Blob([content]).size
      let progover = (textlen === 0 && images.length === 0 && video === null)
      let proglen = Math.ceil((textlen / 280) * 100)
      if (proglen > 100) {
        progover = true
        proglen = 100
      }
      let storage = storageFee(textlen, images, video)
      let allDone = async e => {
        e.target.loading = true
        let success = await vnode.attrs.onsave({content: prose.content, images, video, storage})
        e.target.loading = false
        if (success) {
          prose.destroy()
          
          setupProse(document.getElementById("prose"))
          images = []
          video = null
          user = await api.userDetails(api.profile.sub)
          m.redraw()
        }
      }

      return <>
        <sl-dialog label="Charging for Tweets??" class="dialog-pricing">
          {user?.account ? <>
            <p>Stiiks.Social is a blockchain service - posts and images and such
              are stored on Solana and IPFS.</p>
            <p>So you pay a fraction of a penny to store it - or more, if large
            attachments are added - and that post has a permanent link that isn't
            owned by any company. Not even by Stiiks.Social. It's yours; you paid for it.</p>
            {api.isAllAccess() ? <>
              <p><strong>Posting is free for you, as part of the Sunnies365 Unlimited Free-Talk
                 stimulus package.</strong></p>
              </> : <>
                <p>This post will cost <strong>{storage.estimatedCost}</strong> as it's
                about <span>{storage.formattedSize}</span> in size.</p>
                <p>You have <strong>{api.totalCreds(user.account)} CREDS</strong> in your account.</p>
              </>}
          </> : <>
            <p>Your account hasn't been created, so you are unable to post at
               the moment. Use <strong>Buy Credits</strong> in the menu to set
               things up.</p>
          </>}
          <sl-button slot="footer" variant="primary"
            onclick={() => showDialog(false)}>Close</sl-button>
        </sl-dialog>

        <form class="editor">
          <sl-avatar image={vnode.attrs.image} label={"@" + vnode.attrs.username}
            shape="rounded"></sl-avatar>
          <sl-select value="everyone" size="small" filled disabled>
            <sl-option value="everyone">Everyone</sl-option>
            <sl-option value="stiiks">Stiiks</sl-option>
            <sl-option value="sunnies">Sunnies</sl-option>
          </sl-select>
          <div id="prose" oncreate={v => setupProse(v.dom)}></div>
          {images.length > 0 &&
            <div class="content-group">
              {images.map((img, index) => <div class="content-image">
                <sl-icon-button onclick={e => removeImage(index)} name="x-lg" label="Remove"></sl-icon-button>
                <img src={img.objectURL} />
              </div>)}
            </div>}
          {video &&
            <div class="content-group">
              <div class="content-video">
                <sl-icon-button onclick={e => video = null} name="x-lg" label="Remove"></sl-icon-button>
                <video src={video.objectURL} controls />
              </div>
            </div>}
          <div class="info" id="emoji-anchor">
            <button onclick={() => showDialog(true)}>
            {api.isAllAccess() ? <>
                <sl-icon name="emoji-sunglasses"></sl-icon> SUNNIES365
              </> : <>
                <sl-icon name="lightning-charge"></sl-icon>
                {user?.account ? storage.estimatedCost : "READ ONLY"}
              </>}
            </button>
            <sl-button-group label="Attachments">
              <sl-icon-button onclick={toggleEmojiPicker} name="emoji-kiss" label="Emoji"></sl-icon-button>
              <sl-icon-button onclick={attachImage} name="file-image" label="JPEG"></sl-icon-button>
              <sl-icon-button onclick={attachVideo} name="film" label="Video"></sl-icon-button>
            </sl-button-group>
          </div>
          <div class="finish">
            <sl-progress-ring class={progover && 'overflow'} value={proglen}></sl-progress-ring>
            <sl-button onclick={allDone} disabled={progover}>Done</sl-button>
          </div>
        </form>
      </>
    }
  }
}
