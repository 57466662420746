import m from 'mithril'
import api from './api'
import utils from './utils'

const tasks = []

window.onbeforeunload = function() {
  if (tasks.length > 0) {
    return "Your work is still being saved. Exit anyway?"
  }
}

export default {
  async savePost(post) {
    //
    // Build the JSON, save the post. 
    //
    let form = new FormData()
    let obj = {
      content: [],
      storage: post.storage
    }
    if (post.content) {
      // console.log(post.content)
      obj.content.push({type: "text", source: post.content})
    }

    let attached = post.images
    let attachtype = 'image'
    if (post.video) {
      attached = [post.video]
      attachtype = 'video'
    }
    attached.map((src, i) => {
      let ext = src.name.split(".").pop()
      let source = `file${i}.${ext}`
      form.append(`file${i}`, src, source)
      obj.content.push({type: attachtype, source})
    })
    let blob = new Blob([JSON.stringify(obj)], {
        type: 'application/json'
    })
    form.append("json", blob, "index.json")
    return await api.savePost(form)
  }
}
