import {
  menu_default
} from "../../chunks/chunk.7R35LA52.js";
import "../../chunks/chunk.JOW3UVWD.js";
import "../../chunks/chunk.Z75LPS3C.js";
import "../../chunks/chunk.JS655M6J.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.MAD5PUM2.js";
export {
  menu_default as default
};
