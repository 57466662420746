import m from 'mithril'
import { jsonDateParser } from "json-date-parser"

//
// Deep copy an object.
//
function dup (obj) {
  return JSON.parse(JSON.stringify(obj), jsonDateParser)
}

//
// Measure a Mithril element's size when it's rendered
//
function measure(ele) {
  return new Promise(resolve => {
    let div = document.createElement("div")
    if (ele.tag === 'img') {
      ele.attrs.onload = e => {
        resolve([e.target.clientWidth, e.target.clientHeight, e.target])
        document.body.removeChild(div)
      }
    }
    div.style.visibility = "hidden"
    div.style.display = "inline-block"
    div.style.fontSize = "16px"
    div.style.whiteSpace = "nowrap"
    m.render(div, ele)
    document.body.appendChild(div)
    if (ele.tag !== 'img') {
      resolve([div.clientWidth, div.clientHeight])
      document.body.removeChild(div)
    }
  })
}

//
// Working space in localStorage, used for state
//
function workingState(props = null) {
  let str = localStorage.getItem("state")
  let state = str ? JSON.parse(str) : {}
  if (props)
    localStorage.setItem("state", JSON.stringify(Object.assign(state, props)))
  return state
}

//
// Get page coordinates for an element
//
function pos (ele, base = document.body) {
  let rect = ele.getBoundingClientRect(), page = base.getBoundingClientRect()
  return {top: rect.top - page.top, left: rect.left - page.left, width: rect.width, height: rect.height,
    right: page.width - rect.width}
}

function randomIn(items) {
  return items[Math.floor(Math.random() * items.length)]
}

function randId() {
  return Math.random().toString(36).substr(2)
}

const id = (() => {
  let currentId = 0
  const map = new WeakMap()

  return (object) => {
    if (!map.has(object)) {
      map.set(object, ++currentId)
    }

    return map.get(object)
  }
})()

function timeBlur(at) {
  let today = new Date()
    , yesterday = new Date(today - (24 * 60 * 60 * 1000))
    , month = at.getMonth()
    , year = at.getFullYear()
    // , firstWeekday = new Date(year, month, 1).getDay()
    , lastDateOfMonth = new Date(year, month + 1, 0).getDate()
    , offsetDate = at.getDate() - 1 // + firstWeekday - 1 (for first day of the week)
    , index = 1 // start index at 0 or 1, your choice
    // , weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7)
    , week = index + Math.floor(offsetDate / 7)

  let weekn, yearn = ""
  if (month === today.getMonth() && year === today.getFullYear() && at.getDate() === today.getDate()) {
    return 'today'
  } else if (month === yesterday.getMonth() && year === yesterday.getFullYear() && at.getDate() === yesterday.getDate()) {
    return 'yesterday'
  }

  if (today - at > 31536000000) {
    yearn = " " + year
  }
  if (week === 1) {
    weekn = "1st"
  } else if (week === 2) {
    weekn = "2nd"
  } else if (week === 3) {
    weekn = "3rd"
  } else {
    weekn = `${week}th`
  }
  return `the ${weekn} week of ${at.toLocaleString('default', { month: 'long' })}${yearn}`
}

function timeAgo(from_time, to_time = new Date()) {
  if (Number(from_time) == 0)
    return ''

  let from_i = Math.floor(from_time / 1000)
  let to_i = Math.floor(to_time / 1000)
  let mins = Math.round(Math.abs(to_i - from_i)/60)

  if (mins == 0)
    return '1m'
  if (mins >= 1 && mins <= 45)
    return mins + 'm'
  if (mins >= 46 && mins <= 90)
    return '1h'
  if (mins >= 91 && mins <= 1440)
    return Math.round(mins / 60) + 'h'
  if (mins >= 1441 && mins <= 2880)
    return '1d'
  if (mins >= 2881 && mins <= 4320)
    return '2d'
  if (mins >= 4321 && mins <= 525600)
    return from_time.toLocaleString('default',
      {month: 'short', day: 'numeric'})
  return from_time.toLocaleString('default',
    {month: 'short', day: 'numeric', year: 'numeric'})
}

function timeFormat(at) {
  return at.toLocaleString('default',
    {hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric', year: 'numeric'})
}

function download(options) {
	var url = options.url || ("data:" + options.mimeType + ";charset=UTF-8," + encodeURIComponent(options.contents))
	var link = document.createElement('a')
	link.setAttribute('download', options.filename)
	link.setAttribute('href', url)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

function copyLink() {
  if (navigator.share) {
    navigator.share({url: window.location.href})
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(window.location.href)
  }
}

function readBlob(blob) {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onload = function (e) {
      resolve(e.target.result)
    }
    reader.readAsDataURL(blob)
  })
}

async function imgblat(files, opts = {maxWidth: 1024, resizeAbove: 1024 * 200}) {
  let i = 0 
  let allow = ['png', 'jpeg', 'gif', 'bmp', 'webp']
  let obj = {images: [], errors: []}
  for (let src of files) {
    try {
      let ext = null

      //
      // Detect and possibly correct the file extension
      //
      if (src.name) {
        ext = src.name.split(".").pop()
        if (ext) {
          ext = ext.toLowerCase()
        }
        if (ext === 'jpg') { /* fix up for the mime type */
          ext = 'jpeg'
        }
      }
      if (!allow.includes(ext)) {
        ext = (src.type || "").split("/").pop()
        if (!allow.includes(ext)) {
          throw new Exception("Image file format couldn't be detected")
        }
        src.name += "." + ext
      }

      //
      // Resize incredibly large images - eventually we'll have settings for
      // deciding how to compress and save space.
      //
      let callback = function (s, d) {
        s.objectURL = d
        obj.images.push(s)
      }
      let data = await readBlob(src)
      if (ext === 'gif' || ext === 'webp' || (src.size && src.size <= opts.resizeAbove)) {
        callback(src, data)
      } else {
        let siz = await measure(m("img", {src: data}))
        let scale = Math.min((opts.maxWidth ? opts.maxWidth / siz[0] : 1.0), (opts.maxHeight ? opts.maxHeight / siz[1] : 1.0))
        if (scale < 1.0) {
          let elem = document.createElement('canvas')
          elem.width = scale * siz[0]
          elem.height = scale * siz[1]
          let ctx = elem.getContext('2d')
          ctx.drawImage(siz[2], 0, 0, elem.width, elem.height)
          let blob = await new Promise(resolve => elem.toBlob(resolve, 'image/' + ext))
          src = new File([blob], src.name, {type: 'image/' + ext, lastModified: Date.now()})
          callback(src, elem.toDataURL('image/' + ext))
        } else {
          if (!(src instanceof File)) {
            src = new File([src], src.name, {type: 'image/' + ext, lastModified: Date.now()})
          }
          callback(src, data)
        }
      }
    } catch (error) {
      src.message = error.message
      obj.errors.push(src)
    }
  }
  return obj
}

imgblat.readBlob = readBlob

export default {dup, id, measure, randId, randomIn, pos, timeAgo, timeBlur, download,
  timeFormat, imgblat, copyLink, workingState}
