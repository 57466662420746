import m from "mithril"
import api from "../api"
import Layout from "./layout"
import Solana from "../solana"

export default function CreditsPage(vnode) {
  let user
  let profile
  let loading = false
  let fundAccount = async (e, creds) => {
    if (!loading) {
      loading = true
      e.preventDefault()

      try {
        let {details} = await api.userDetails(api.profile.sub)
        // console.log([details, api.profile.addr, creds])
        await api.payAccount(details.avatar.address, api.profile.addr, creds)
        m.route.set("/")
      } catch (e) {
        console.log(e)
        loading = false
        m.redraw()
      }
    }
  }
  let resetLogin = async e => {
    await api.signedTxLogin()
    m.redraw()
  }

  return {
    oninit: async vnode => {
      let sol = new Solana()
      profile = await sol.onchainProfile(api.profile.sub)
      user = await api.userDetails(api.profile.sub)
      m.redraw()
    },

    view: vnode => {
      if (!user)
        return

      return <Layout>
        {api.isAllAccess() ?
          <div id="page">
            <h1>Access Granted</h1>

            {profile?.avatar ?
              <div class="narrow">
                <p>As an esteemed member of Sunnies Worldwide Consortium, you
                   receive unlimited access to Stiiks.Social. You can rattle on
                   and on without concern.</p>

                <div class="buttons">
                  <sl-button variant="primary" onclick={() => m.route.set("/")}>YES I KNOW</sl-button>
                </div>

                <p><em>(If you no longer own a Sunnies NFT in this wallet and are
                   receiving this message in error, click this button.)</em></p>

                <div class="buttons">
                  <sl-button onclick={resetLogin}>Reset Login</sl-button>
                </div>
              </div> :

              <div class="narrow">
                <p>Yo! You have a Sunnies Worldwide Consortium ALL-ACCESS token
                   in your wallet. That's sooooo cooooool!! 🥹</p>

                <p>Even though you can post without needing to pay credits, you
                   will need to create a token account (costs like ~0.02 SOL) to
                   broadcast your bounteous presence across the blockchain.</p>

                <div class="buttons">
                  <sl-button variant="primary" onclick={e => fundAccount(e, 0)} loading={loading}>Open Account</sl-button>
                  <sl-button onclick={() => m.route.set("/")} disabled={loading}>Not now</sl-button>
                </div>
              </div>
            }
          </div> :

          <div id="page">
            <h1>{profile?.avatar ? "Add Credits" : "Open Your Account"}</h1>

            <div class="narrow">
              {profile?.avatar ? <>
                <p>Need to top up? That's actually surprising. Sounds like you're
                   burning through credits! You have {api.totalCreds(user.account)} left.</p>
              </> : <>
                <p>While your profile has been saved, you don't have any credits to
                  store posts, images and such on the network. Your profile also
                  isn't public.</p>

                <p>Of course, you can use the site read-only if you like!</p>

                <p>Setting up an account is 0.1 SOL, which gives you 10k credits.
                   Posting costs 10 credits (unless you post a lot of images, which
                   can increase the cost).</p>

                <p><strong>IF YOU HAVE A SUNNIES NFT, PUT IT IN THIS WALLET!
                   SUNNIES RECEIVE UNLIMITED FREE POSTING.</strong></p>
              </>}

              <div class="buttons">
                <sl-button variant="primary" onclick={e => fundAccount(e, 1)} loading={loading}>Pay 0.1 SOL</sl-button>
                <sl-button onclick={() => m.route.set("/")} disabled={loading}>Not now</sl-button>
              </div>
            </div>

          </div>
        }
      </Layout>
    }
  }
}
