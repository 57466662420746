import {
  skeleton_default
} from "../../chunks/chunk.JXVOZJXT.js";
import "../../chunks/chunk.J3OTQKMJ.js";
import "../../chunks/chunk.UVFVIZIV.js";
import "../../chunks/chunk.JS655M6J.js";
import "../../chunks/chunk.TUVJKY7S.js";
import "../../chunks/chunk.MAD5PUM2.js";
export {
  skeleton_default as default
};
