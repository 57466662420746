import {InputRule, smartQuotes, emDash, ellipsis, wrappingInputRule, textblockTypeInputRule} from "prosemirror-inputrules"
import {NodeRange, Schema, DOMSerializer} from "prosemirror-model"
import normalizeUrl from 'normalize-url'
import twemoji from "twemoji"

import emoji from "./emoji"
import parser from "./parser"
import {checkTld} from "./tlds"

export const schema = new Schema({
  nodes: {
    doc: {
      content: "inline*"
    },

		text: {
      group: "inline"
    },

    emoji: {
      inline: true,
      group: "inline",
      attrs: {
				alt: {},
        src: {},
				class: {default: 'emoji'}
      },
      draggable: true,
      parseDOM: [{tag: "img[class='emoji']", getAttrs(dom) {
        return {
					alt: dom.getAttribute("alt"),
          class: dom.getAttribute("class"),
          src: dom.getAttribute("src")
        }
      }}],
      toDOM(node) { return ["img", node.attrs] }
    },

    link: {
      inline: true,
      group: "inline",
			content: "text*",
      attrs: {
        "data-link": {},
				class: {default: "link"}
      },
      draggable: true,
      parseDOM: [{tag: "a[class='link']", getAttrs(dom) {
        return {
					class: dom.getAttribute("class"),
          "data-link": dom.getAttribute("data-link")
        }
      }}],
      toDOM(node) { return ["a", node.attrs, 0] }
    },

    mention: {
			inline: true,
      group: "inline",
			content: "text*",
      attrs: {
        "data-id": {},
				class: {default: 'mention'}
      },
      draggable: true,
      parseDOM: [{tag: "a[class='mention']", getAttrs(dom) {
        return {
					class: dom.getAttribute("class"),
          "data-id": dom.getAttribute("data-id")
        }
      }}],
      toDOM(node) { return ["a", node.attrs, 0] }
    },

    hard_break: {
      inline: true,
      group: "inline",
      selectable: false,
      parseDOM: [{tag: "br"}],
      toDOM() { return ["br"] }
    }
  }
})

const vs16RegExp = /\uFE0F/g;
const zeroWidthJoiner = String.fromCharCode(0x200d);
const removeVS16s = rawEmoji => (rawEmoji.indexOf(zeroWidthJoiner) < 0 ? rawEmoji.replace(vs16RegExp, '') : rawEmoji);

export function toCodePoints(unicodeSurrogates) {
  const points = []
  let char = 0
  let previous = 0
  let i = 0
  while (i < unicodeSurrogates.length) {
    char = unicodeSurrogates.charCodeAt(i++)
    if (previous) {
      points.push((0x10000 + ((previous - 0xd800) << 10) + (char - 0xdc00)).toString(16))
      previous = 0
    } else if (char > 0xd800 && char <= 0xdbff) {
      previous = char
    } else {
      points.push(char.toString(16))
    }
  }
  return points
}

export function shortToEmoji(short) {
	let orig = `:${short}:`
  let cp = emoji.full[short]
	if (cp) {
    // let id = toCodePoints(removeVS16s(cp)).join('-')
		let id = twemoji.convert.toCodePoint(cp)
    return {src: `https://twemoji.maxcdn.com/v/latest/svg/${id}.svg`, alt: orig}
  }
  cp = emoji.local[short]
  if (cp) {
    return {src: cp, alt: orig}
  }
}

function textToLink(tld, full) {
  let hasTld = checkTld(tld)
  if (hasTld) {
    let href = normalizeUrl(full)
    return {"data-link": href}
  }
}

function inlineInputRule(regexp, nodeType, matchFn, isText) {
  return new InputRule(regexp, (state, match, start, end) => {
    let attrs = matchFn(match)
    if (attrs) {
      let tr = state.tr.replaceRangeWith(start, end, schema.node(nodeType, attrs, isText ? schema.text(match[1]) : null))
      if (match[3]) {
        tr.insertText(match[3])
      }
      return tr
    }
  })
}

export const schemaInputRules = {rules:
  smartQuotes.concat(ellipsis, emDash,
    inlineInputRule(/:(\w+):$/, "emoji", match => shortToEmoji(match[1]), false),
    inlineInputRule(/(@(\w+))(\W)?$/, "mention", match => ({"data-id": match[1]}), true),
    inlineInputRule(/((?:https?:\/\/)?(?:\w+\.)+(\w+)(?:\/[-!?#+~%\/.\w=&;@]*)?)([^-!?#+~%\/.\w=&;@])?$/, "link", match => textToLink(match[2], match[1]), true),
  )
}

export function downtextConverter(content, fn) {
  let waxeye = new parser.Parser()
  let dt = waxeye.parse(content)
  let nodes = []
  for (let c of dt.children) {
    let node
    switch (c.type) {
      case "Mention":
        let name = c.children.join("")
        node = fn("Mention", {name})
			break

      case "Link":
      {
				let hasTld = false
				let full = ""
				for (let p of c.children) {
					let part = p.children.join("")
					full += part
					if (p.type == "Tld") {
						hasTld = checkTld(part)
					}
				}
			
        if (hasTld) {
          let href = normalizeUrl(full)
          node = fn("Link", {href, full})
				} else {
          node = fn("Text", full)
				}
			}
			break

			case "Emoji":
      {
				let short = c.children.join("")
        let attrs = shortToEmoji(short)
				if (attrs) {
          node = fn("Emoji", attrs)
				} else {
          node = fn("Text", short)
				}
			}
			break

			default:
				if (c == "\n") {
          node = fn("Break", "\n")
				} else {
          node = fn("Text", c)
				}
			break
    }
    nodes.push(node)
	}
  return nodes
}

export function downtextToHtml(content) {
  return downtextConverter(content, (blockType, ele) => {
    if (blockType == "Mention") {
      return `<a href="/${ele.name}">${ele.name}</a>`
    } else if (blockType == "Link") {
      return `<a href="${ele.href}">${ele.full}</a>`
    } else if (blockType == "Emoji") {
      return `<img class="emoji" src="${ele.src}" alt="${ele.alt}" />`
    } else if (blockType == "Break") {
      return "<br />"
    }
    return ele
  })
}

export const domSerializer = DOMSerializer.fromSchema(schema)

export function defaultParser(content) {
  let nodes = downtextConverter(content, (blockType, ele) => {
    if (blockType == "Mention") {
      return schema.node("mention", {"data-id": ele.name}, schema.text(ele.name))
    } else if (blockType == "Link") {
			return schema.node("link", {"data-link": ele.href}, schema.text(ele.full))
    } else if (blockType == "Emoji") {
			return schema.node("emoji", ele)
    } else if (blockType == "Break") {
			return schema.node("hard_break")
    }
    return schema.text(ele)
  })
	return schema.node("doc", null, nodes)
}

export function defaultSerializer(node) {
	let str = ""
	if (node.content?.content) {
		for (let c of node.content.content) {
      if (c.type?.name == "hard_break") {
        str += "\n"
      } else if (c.text) {
				str += c.text
			} else if (c.attrs?.alt) {
				str += c.attrs.alt
			} else if (c.content) {
				str += defaultSerializer(c)
			}
		}
  }
	return str
}
